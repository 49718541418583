import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import TextField from "../Form/TextField/TextField";
import { Grid } from "@material-ui/core/";
import MediaFileUplaoder from "../Form/FileUplaoder/MediaFileUplaoder";
import SimpleBackdrop from "../../components/Form/Backdrop/SimpleBackdrop";
import "./MediaLibrary.css";
import SelectField from "../Form/SelectField/SelectField";
import axios from "axios";
import NewTextField from "../Form/TextField/NewTextField";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const UploadFromDevice = forwardRef((props, ref) => {
  const childRef = useRef(null);
  const {
    uploaded,
    setUploaded,
    filePathData,
    setFilePathData,
    selectedFile,
    setSelectedFile,
    setErrorState,
    errorState,
    progress,
    setProgress,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [imageName, setImageName] = useState(props.imageName);

  // const [selectedFile, setSelectedFile] = useState(null);

  const [getUrl, setGetUrl] = useState(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  //=================================== visibility part ========================================//
  const [visibility, setVisibility] = React.useState("Public");
  const handleChangesVisibility = (event) => {
    setVisibility(event.target.value);
  };

  const options = [
    {
      label: "Public",
      value: "Public",
      subtitle: "Visible to everyone",
    },
    {
      label: "Private",
      value: "Private",
      subtitle: "Visible only to the enrolled",
    },
  ];
  const changeMediaPermission = async () => {
    let data = {
      key: filePathData,
      visibility: visibility === "Public" ? "public" : "private",
    };
    try {
      const response = await axios.post(
        `${baseUrl}/changeMediaPermission`,
        data
      );
      if (response?.status === 200) {
        setFilePathData(response?.data?.key?.replace("\\", ""));
      }
    } catch (error) {
      console.error("Error uploading file", error, 346);
    }
  };

  useEffect(() => {
    if (filePathData) {
      changeMediaPermission();
    }
  }, [visibility]);

  //=================================== visibility part ========================================//

  //=============================== select file from the device =============================//
  const handleFileUpload = async (event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const item = event.target.files[0];
      const fileMaxSize = item?.type?.startsWith("image/")
        ? process.env.REACT_APP_MAX_SIZE_FOR_FILE
        : process.env.REACT_APP_MAX_SIZE_FOR_VIDEO;

      setSelectedFile(item);
      if (props.setSelectedFile) {
        props.setSelectedFile(item);
      }
      const maxSize = Number(fileMaxSize * 1024 * 1024);

      if (Number(item.size) > maxSize) {
        setSnackbarMessage(
          `${
            item?.type?.startsWith("image/")
              ? "The selected file exceeds the max limit of 5 MB(5000KB)"
              : "The selected file exceeds the max limit of 1024 MB(1024000KB)"
          }`
        );
        setErrorState(true);
        setSnackbarSeverity("FieldError");
        setUploaded(false);
        setShowSnackbar(true);
        setFilePathData(null);
        setGetUrl(null);
        props.setFileUploadError(true);
        return false;
      } else {
        setErrorState(false);
        props.setFileUploadError(false);
        setUploaded(true);
        setShowSnackbar(false);
        setSnackbarMessage("");
        setSnackbarSeverity("");
      }

      // return '' ;
      const reader = new FileReader();

      reader.onload = function (event) {
        const result = reader.result;
        const isImage = result?.startsWith("data:image/");
        //=============================== image preview ===================================//
        if (isImage) {
          const img = new Image();
          // setIsImage(true);

          img.onload = function () {
            setWidth(img.naturalWidth);
            setHeight(img.naturalHeight);

            //check if props.setWidth is defined then set width
            if (props.setWidth) {
              props.setWidth(img.naturalWidth);
            }

            //check if props.setHeight is defined then set height
            if (props.setHeight) {
              props.setHeight(img.naturalHeight);
            }
          };

          img.src = event.target.result;
        }
        //=============================== image preview ===================================//
      };

      reader.onloadend = () => {
        // setPreview(reader.result);
      };

      reader?.readAsDataURL(item);
    } else {
      console.error("No file selected", 342);
    }
  };
  //=============================== select file from the device =============================//

  const clickButton = () => {
    if (childRef.current) {
      childRef.current.clickButton();
    }
  };

  useImperativeHandle(ref, () => ({
    clickButton: () => clickButton(),
    uploadFile: () => uploadFile(),
  }));

  const handleChanges = (fieldName) => (event) => {
    if (fieldName === "imageName") {
      setImageName(event.target.value);
      props.setImageName(event.target.value);
    }
  };
  //======================= file uploading to the server ================================//
  const uploadFile = async () => {
    setOpenBackdrop(true);

    if (!selectedFile) return;

    //set is_private
    let is_private = 0;
    if (visibility === "Private") {
      is_private = 1;
    }
    try {
      props.onFileUpload(
        filePathData,
        selectedFile?.name,
        Number(selectedFile?.size) / 1024,
        selectedFile?.type,
        width,
        height,
        is_private
      );
    } catch (error) {
      console.error("Error uploading file", error, 346);
    }
    setOpenBackdrop(false);
  };
  //======================= file uploading to the server ================================//
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  useEffect(() => {
    if (errorState) {
      setShowSnackbar(true);
      setSnackbarMessage(
        `${
          selectedFile?.type?.startsWith("image/")
            ? "The selected file exceeds the max limit of 5 MB(5000KB)"
            : "The selected file exceeds the max limit of 1024 MB(1024000KB)"
        }`
      );
      setSnackbarSeverity("FieldError");
    }
  }, [errorState]);

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="MediaLibPanelTextField">
            <NewTextField
              label="Media Name"
              required={true}
              value={props.imageName}
              // handleValidation={handleValidation}
              handleChange={(val, e) => handleChanges("imageName")(e)}
              // handleChange={handleChanges("imageName")}
              placeholder=""
              count={50}
              helperText={
                props.imageName?.length === 0 ? props.imageErrorMessage : ""
              }
              error={
                props.imageName?.length === 0
                  ? props.showImageNameError
                    ? "Required"
                    : ""
                  : ""
              }
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <MediaFileUplaoder
            FieldError={props.showFileUploadError ? "FieldError" : ""}
            label="Upload from your device"
            required={true}
            TooltipStatus={false}
            TooltipText="Tool tip Text"
            filename={selectedFile?.name}
            filesize={`${parseFloat(selectedFile?.size / 1024 ** 2)?.toFixed(
              2
            )} MB`}
            UploadedStatus={
              !errorState ? (
                uploaded ? (
                  <span>&nbsp;●&nbsp;Uploading media..</span>
                ) : (
                  <span>
                    <CheckCircleRoundedIcon
                      sx={{
                        marginBottom: "-2px",
                        color: "#0c9444 !important",
                        fontSize: "12px",
                      }}
                    />{" "}
                    Uploaded
                  </span>
                )
              ) : (
                ""
              )
            }
            filePath={`courses/course-images/`}
            uploadButton="Upload Image"
            fileType={
              "image" ||
              "audio" ||
              "video" ||
              "application/vnd.ms-excel" ||
              "application/msword" ||
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              "text/csv"
            }
            showThumb={true}
            helpertext={
              !selectedFile && props.showFileUploadError
                ? "Please upload a file from your device"
                : ""
            }
            onUpload={props.onFileUpload}
            mediaName={imageName}
            nameMandatory="1"
            ref={childRef}
            setWidth={setWidth}
            setHeight={setHeight}
            // setSelectedFile={handleFileSelected}
            visibility={visibility}
            uploaded={uploaded}
            setUploaded={setUploaded}
            getUrl={getUrl}
            setGetUrl={setGetUrl}
            filePathData={filePathData}
            setFilePathData={setFilePathData}
            Error={
              !selectedFile && props.showFileUploadError
                ? "1px solid #d32f2f"
                : ""
            }
            showSnackbar={showSnackbar}
            setShowSnackbar={setShowSnackbar}
            snackbarSeverity={snackbarSeverity}
            setSnackbarSeverity={setSnackbarSeverity}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleFileUpload={handleFileUpload}
            disabled={selectedFile ? true : false}
            setErrorState={setErrorState}
            errorState={errorState}
            progress={progress}
            setProgress={setProgress}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <div
            className="MediaLibPanelTextField"
            style={{ marginTop: "-15px" }}
          >
            <SelectField
              label="Visibility"
              required={true}
              options={options}
              handleChange={handleChangesVisibility}
              selectedOption={visibility}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default UploadFromDevice;
