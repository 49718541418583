import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getUserAuthData, getUserData } from "../../hooks/HelperFunctions";
import { deepPurple } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Logo from "../../assets/imgs/admin-logo.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CryptoJS from "crypto-js";

// Menu Icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import "../../assets/css/style.css";
import "./Header.css";
import "../../assets/css/form.css";
import "../../assets/css/RolePermissionDetails.css";
import { Hidden, IconButton } from "@mui/material";

function Header(props) {
  const takbirLogo =
    "https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png";

  let fullHost = window.location.hostname;
  let splittedHost = fullHost.split(".");
  let subDomain = splittedHost[0];
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userToken = localStorage.getItem("userToken");

  var signinToken = CryptoJS.AES.encrypt(
    JSON.stringify({
      email: userData.email,
      phone_number: userData.phone_number,
      userToken: userToken,
    }),
    "token-based-signin-aglbnd-854256-secret-key"
  ).toString();

  let takbirUrl =
    subDomain == "stage-admin"
      ? "https://main-stage.mytakbir.com/"
      : "https://mytakbir.com/";

  takbirUrl += `?signinToken=${signinToken}`;

  const navigate = useNavigate();

  useEffect(() => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
    axios
      .get(`${baseUrl}/checkTokenValidation`)
      .then((result) => {
        console.log(result.data.body);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message == "Unauthenticated.") {
          // clear all local data
          localStorage.clear();
          navigate("/signin", { replace: true });
        }
      });
  }, []);

  const logoutHandler = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .get(`${baseUrl}/logout`)
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          const userData = getUserData();

          // if (userData?.userRole == "student") {
          //   // set org-website url
          //   let orgWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;
          //   const subDomain = localStorage.getItem("subDomain");
          //   orgWebsiteUrl = orgWebsiteUrl.replace('sub-domain', subDomain);
          //   // clear all local data
          //   localStorage.clear();
          //   // window.open(`${orgWebsiteUrl}/?signout=success`);
          //   window.location.href = `${orgWebsiteUrl}/?signout=success`;

          // } else

          if (userData?.userRole == "org") {
            const subDomain = localStorage.getItem("subDomain");
            // clear all local data
            localStorage.clear();
            navigate(`/signin?org=${subDomain}`, { replace: true });
          } else {
            // clear all local data
            localStorage.clear();
            navigate("/signin", { replace: true });
          }
        } else {
          console.log("Logout Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Logout menue unauthorized
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout menue unauthorized

  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 50) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  return (
    <div>
      <div className={fix ? "Top" : "Bottom"}>
        <div className={fix ? "HeaderDefault Headerfixed" : "HeaderDefault"}>
          <div className={fix ? "AlertShow" : "Bottom"}> {props.AlertBody}</div>

          <div className="HeaderContainer">
            <div className="Header">
              <div className="HdrLeftCol">
                <div className="IconMenu">{props.IconMenu}</div>
                {takbirLogo?.length > 0 && (
                  <a
                    className="Branding"
                    href={takbirUrl?.length > 0 ? takbirUrl : "#"}
                  >
                    <img src={takbirLogo} />
                  </a>
                )}
              </div>

              <div className="HdrRightCol">
                <div className="DesktopView">{props.ButtonBody}</div>

                {/* <Button variant="text" className="NotificationButton">
              <div className="NotificationCount">1</div>
              <NotificationsRoundedIcon />
              </Button> */}

                {/* <Avatar onClick={handleClick}>{Array.from(userData.firstName)[0]}</Avatar> */}
                {userData.image ? (
                  <Avatar
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ bgcolor: deepPurple[300] }}
                    src={userData.image ? userData.image : ""}
                  ></Avatar>
                ) : (
                  <Avatar
                    onClick={handleClick}
                    sx={{ bgcolor: deepPurple[300] }}
                  >
                    {" "}
                    {userData.firstName
                      ? Array.from(userData.firstName)[0]
                      : ""}{" "}
                  </Avatar>
                )}

                <div className="">
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className="DropdownMenu"
                  >
                    <Hidden smUp>
                      <MenuItem className="MenuItemCrossIcon">
                        <IconButton
                          sx={{
                            marginRight: "-14px",
                            border: "1px solid #e4e8ec",
                            borderRadius: "12px",
                          }}
                          onClick={handleClose}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </MenuItem>
                    </Hidden>
                    <MenuItem onClick={handleClose}>
                      <div className="ProfileSection">
                        {userData.image ? (
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[300],
                              width: 96,
                              height: 96,
                            }}
                            src={userData.image ? userData.image : ""}
                          ></Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[300],
                              width: 96,
                              height: 96,
                            }}
                          >
                            {" "}
                            {userData.firstName
                              ? Array.from(userData.firstName)[0]
                              : ""}{" "}
                          </Avatar>
                        )}
                        <div>
                          <h1>
                            {userData.firstName} {userData.lastName}
                          </h1>
                          <p>
                            <a href="#">{userData.email}</a>
                          </p>
                        </div>
                      </div>
                    </MenuItem>

                    {/* <span className="divider"></span> */}
                    {/* <MenuItem onClick={handleClose}><HomeRoundedIcon /> Home</MenuItem> */}
                    {/* <MenuItem onClick={handleClose}><MenuBookRoundedIcon /> Courses</MenuItem> */}
                    {/* <span className="divider"></span> */}
                    {/* <MenuItem onClick={handleClose}><NotificationsRoundedIcon /> Alerts</MenuItem>
                <MenuItem onClick={handleClose}><QuestionAnswerRoundedIcon /> Messages</MenuItem> */}
                    {/* <MenuItem onClick={handleClose}><InsertInvitationRoundedIcon /> Calendar</MenuItem> */}
                    {/* <span className="divider"></span> */}
                    {/* <MenuItem onClick={handleClose}><EmojiEventsRoundedIcon /> Certificates</MenuItem> */}
                    {/* <MenuItem onClick={handleClose}><SettingsRoundedIcon /> Settings</MenuItem> */}
                    <div className="MobileView BrowsButton">
                      {props.ButtonBody}
                    </div>
                    <MenuItem onClick={logoutHandler}>
                      <LogoutIcon /> Log Out
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.SidebarBody}
    </div>
  );
}

export default Header;
