import React, { useEffect, useState } from "react";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Modals from "../../../../components/Form/Modals/Modals";
import ModalsResponsiveFullScreen from "../../../../components/Form/Modals/ModalsResponsiveFullScreen";
import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";
import UpdatePersonalInfo from "./forms/UpdatePersonalInfo";
import { withStyles } from "@material-ui/core/styles";

import SideDrawer from "../../../../components/SideDrawer/SideDrawer";

function PersonalInfo(props) {
  const {
    studentData,
    personalInfo,
    setPersonalInfo,
    gradeLevels,
    ageGroups,
    timeZones,
    editProfile,
    setEditProfile,
    triggerSnackbar,
    setFetchLatestData,
    setOpen,
    open,
  } = props;
  const { countries, highestEducation } = props;
  const [manualAddress, setManualAddress] = useState(
    personalInfo?.manualAddress
  );
  // const [selectedFile, setSelectedFile] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  function createData(label, data) {
    return { label, data };
  }

  const birthdayArray = studentData.birthdate
    ? studentData?.birthdate?.split("-")
    : [];
  const birthMonth = birthdayArray[0] ? birthdayArray[0] : "";
  const birthYear = birthdayArray[1] ? birthdayArray[1] : "";

  let birthdateArr = [];
  if (birthMonth.length > 0) {
    birthdateArr.push(birthMonth);
  }
  if (birthYear.length > 0) {
    birthdateArr.push(birthYear);
  }

  const formattedTimeZone = (timezone) => {
    const findData = timeZones?.find((data) => data?.timezone === timezone);
    // console.log(fieldName, fieldData, 345, findData);
    let formattedTimeZone = `${timezone} | GMT ${findData?.gmtOffset}`;
    return formattedTimeZone;
  };

  const rows = [
    createData(
      "Full name",
      studentData.firstName ? (
        <span>
          {studentData.firstName} {studentData.middleName}{" "}
          {studentData.lastName}
        </span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add name
        </a>
      )
    ),
    createData(
      "Gender",
      studentData.gender ? (
        <span>{studentData.gender}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add gender
        </a>
      )
    ),
    createData(
      "Country",
      studentData.countryName ? (
        <span>{studentData.countryName}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add country
        </a>
      )
    ),
    createData("Address", (studentData?.manualAddress == 0 && studentData?.address) || 
    (studentData?.manualAddress == 1 && 
      studentData?.streeAddress != "" &&
      studentData?.city != "" &&
      studentData?.state != "" &&
      studentData?.zipCode != "") ? 
      (<span>{studentData?.manualAddress == 0 ? studentData.address : `${studentData?.streetAddress}, ${studentData?.city}, ${studentData?.state}, ${studentData?.zipCode}`}</span>) : (<a className="addText" onClick={() => OpenModal()}>Add address</a>)),
    createData(
      "WhatsApp number",
      studentData.whatsAppNumber ? (
        <span>{studentData.whatsAppNumber}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add whatsApp number
        </a>
      )
    ),
    createData(
      "Birthday",
      studentData.birthdate && studentData.birthdate !== "-" ? (
        <span>{birthdateArr.join(" ")}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add birthday
        </a>
      )
    ),
    createData(
      "Highest education",
      studentData.heduTitle ? (
        <span>{studentData.heduTitle}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add highest education
        </a>
      )
    ),
    createData(
      "Current profession",
      studentData.currentProfession ? (
        <span>{studentData.currentProfession}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add current profession
        </a>
      )
    ),
    createData(
      "Timezone",
      studentData.timezone ? (
        <span>{`${formattedTimeZone(studentData.timezone)}`}</span>
      ) : (
        <a className="addText" onClick={() => OpenModal()}>
          Add timezone
        </a>
      )
    ),
  ];

  // ==== Name Modals ===== //
  // const [open, setOpen] = useState(false);
  const OpenModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setManualAddress(false);
    setOpen(false);
    setEditProfile(false);
    setDisableSaveBtn(true);
    setPersonalInfo(studentData);
  };
  // ==== Name Modals ===== //

  const showDataPostMessage = (response, dataArray) => {
    setManualAddress(false);
    triggerSnackbar(true, "success", "Your profile has updated.");
    setDisableSaveBtn(true);
    setOpen(false);
    setEditProfile(false);
    setFetchLatestData(true);
    // set profile pic
    console.log(dataArray.image);
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.image = dataArray.image;
    userData.firstName = dataArray.firstName;
    userData.lastName = dataArray.lastName;
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("timezone", dataArray?.timezone);
  };

  const handleSaveStudentData = async (dataArray) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    // console.log(dataArray, 345);

    // dataArray.address = typeof dataArray.address == null? "": dataArray.address;

    if (
      !dataArray.firstName ||
      !dataArray.lastName ||
      dataArray.countryId == 0 ||
      (!dataArray.addressObj && !manualAddress) ||
      !dataArray.timezone
    ) {
      triggerSnackbar(true, "error", "Please fill up all required fields.");
    } else if (
      manualAddress &&
      (!dataArray.streetAddress ||
        !dataArray.city ||
        !dataArray.state ||
        !dataArray.zipCode)
    ) {
      triggerSnackbar(true, "error", "Please fill up all required fields.");
    } else {
      let data = { ...dataArray, manualAddress: manualAddress };
      axios
        .post(`${baseUrl}/studentPortal/editProfile`, data)
        .then((response) => showDataPostMessage(response, dataArray));
    }
  };

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#FCFCFC",
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  //====================================== setting locations ==================================//
  // const [country, setCountry] = useState(null);
  // const [error, setError] = useState(null);
  // const getCountryNameByGeo = async () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         try {
  //           const apiKey = process.env.REACT_APP_API_KEY;
  //           const response = await axios.get(
  //             `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`
  //           );

  //           const country = response.data.results[0].components.country;
  //           localStorage.setItem("countryName", country);
  //           setCountry(country);
  //         } catch (error) {
  //           setError("Unable to fetch country name");
  //           console.error(error);
  //         }
  //       },
  //       (error) => {
  //         setError("Geolocation not supported or permission denied");
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     setError("Geolocation is not available in this browser");
  //   }
  // };
  // useEffect(() => {
  //   const getCountry = localStorage.getItem("countryName");
  //   if ((open || editProfile) && !getCountry) {
  //     getCountryNameByGeo();
  //   }
  // }, [open, editProfile]);
  // useEffect(() => {
  //   if (country) {
  //     const findCountryId = countries?.find(
  //       (countryData) => countryData?.name === country
  //     );
  //     setPersonalInfo({
  //       ...personalInfo,
  //       countryId: findCountryId?.countryId,
  //     });
  //   } else if (country === null) {
  //     setPersonalInfo({
  //       ...personalInfo,
  //       countryId: " ",
  //     });
  //   }
  // }, [country, editProfile]);

  //====================================== setting locations ==================================//

  return (
    <div className="TableContainer oddEvenTable">
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="TableInnerHeader">
        <h3>Profile</h3>
      </div>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="personal info">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className="TableLabel"
                >
                  {" "}
                  {row.label}
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {row.data}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SideDrawer
        title="Edit Profile"
        cancelButtonLabel="Cancel"
        cancelButtonOutlined={true}
        deleteText="Delete Permanently"
        sideOpen="right"
        open={open || editProfile}
        handleDrawerClose={handleClose}
        ButtonLeft="Upload New"
        FooterWithoutTab={true}
        ButtonLabel="Save"
        saveButtonDisabled={disableSaveBtn}
        clickSaveButton={() => handleSaveStudentData(personalInfo)}
        body={
          <div>
            <UpdatePersonalInfo
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              gradeLevels={gradeLevels}
              ageGroups={ageGroups}
              timeZones={timeZones}
              countries={countries}
              highestEducation={highestEducation}
              setDisableSaveBtn={setDisableSaveBtn}
              manualAddress={manualAddress}
              setManualAddress={setManualAddress}
            />
          </div>
        }
      />
    </div>
  );
}
export default PersonalInfo;
