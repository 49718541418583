import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

import CourseOverview from "./steps/CourseOverview";
import Description from "./steps/Description";

import Settings from "./steps/Settings";
import Learners from "./steps/Learners";
import Curriculum from "./steps/Curriculum";
import Prerequisite from "./steps/Prerequisite";
import Track from "./steps/Track";
import Price from "./steps/Price";
import Publish from "./steps/Publish";
import Assignments from "./steps/Assignments";
import CustomizedSnackbar from "../../../../hooks/Snackbar";
import DraggableDialog from "../../../../hooks/Dialog";
import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import Buttons from "../../../../components/Form/Button/Button";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";

import Tooltip from "../../../../components/Form/Tooltip/Tooltip";
import { forEach } from "lodash";

import StepButton from "@material-ui/core/StepButton";
import {
  isValidURL,
  validateYouTubeUrl,
} from "../../../../hooks/HelperFunctions";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

function getInitialValues(currentOrgId, courseId) {
  const initialCourseData = {
    orgId: currentOrgId,
    courseTitle: "",
    description: "",
    courseLanguages: [],
    level: "",
    category: "",
    leadInstructor: "",
    instructors: [],
    enrollmentStartDate: "",
    enrollmentEndDate: "",
    sessionStartDate: "",
    sessionEndDate: "",
    totalSessions: "",
    totalHours: "",
    totalLiveSessions: "",
    whatsAppGroup: "",
    timezone: "",
    courseImage: "",
    courseImageName: "",
    promotionalVideo: "",
    completionCertificate: false,
    gradedExamCertificate: false,
    enableAttendance: false,
    seatsStatus: false,
    seats: "",
    ageGroup: false,
    gradeLevel: false,
    ageGroups: [],
    gradeLevels: [],
    sessionLinkSetting: true,
    zoomLink: "",
    zoomDescription: "",
    courseModules: [
      { modId: 0, title: "Module 01", courseSessions: [], courseResourses: [] },
    ],
    courseLearningObjs: [],
    courseRequirements: [],
    prerequisite: false,
    preCourses: [],
    track: false,
    tracks: [],
    priceCat: "Free",
    price: "",
    promoCodes: [],
    status: "draft",
  };

  const localCourseData = JSON.parse(localStorage.getItem("courseData"));

  // console.log(localCourseData);

  if (courseId == 0 && localCourseData && localCourseData.courseId) {
    return initialCourseData;
  }

  return localCourseData ? localCourseData : initialCourseData;
}

function AddCourse(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const orgId = localStorage.getItem("orgId");

  let { courseId } = useParams();

  const navigate = useNavigate();

  console.log(courseId);

  const [activeStep, setActiveStep] = React.useState(0);

  const [stepErrors, setStepErrors] = React.useState({
    "Course Overview": true,
    Description: false,
    Settings: true,
    Learners: true,
    Curriculum: true,
    Prerequisite: true,
    Price: false,
    Publish: false,
  });

  const [skipped, setSkipped] = React.useState(new Set());
  const [courseData, setCourseData] = useState(
    getInitialValues(orgId, courseId)
  );
  const [dateError, setDateError] = useState(false);
  const [courseRelationalData, setCourseRelationalData] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");

  const [formValidationError, setFormValidationError] = useState(false);

  const steps = [
    "Course Overview",
    "Description",
    "Settings",
    "Learners",
    "Curriculum",
    "Prerequisite",
    "Price",
    "Publish",
  ];

  useEffect(() => {
    if (courseId > 0) {
      setOpenBackdrop(true);
    }
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(
        `${baseUrl}/orgPortal/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`
      )
      .then((result) => {
        // console.log(result.data.body);

        setCourseRelationalData(result.data.courseRelationalData);

        if (courseId > 0) {
          // set course data
          setCourseData(result.data.courseData);
          setOpenBackdrop(false);
        } else {
          // setCourseData("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("courseData", JSON.stringify(courseData));
    // set validation alert
    setFormValidationError(false);
    steps.forEach((element, index) => {
      const validationResult = dataValidation(index, true);
      if (validationResult.error) {
        console.log(steps[index]);
        setFormValidationError(true);
      }
    });
  }, [courseData]);

  useEffect(() => {
    setCourseData({ ...courseData, formValidationError: formValidationError });
  }, [formValidationError]);

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const saveDraft = () => {
    if (!courseData.courseTitle) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else {
      // make course status as draft
      setCourseData({ ...courseData, status: "draft" });
      storeData(1);
      setCheckItisDraft(true);
    }
  };

  const handleExit = () => {
    setDialogTitle("Unsaved changes");
    setDialogBody(
      "You have unsaved changes. You will lose these changes if you exit without saving."
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // return to course listing page
    localStorage.removeItem("courseData");
    navigate("/courses-listing", { replace: true });
  };

  const handleConfirmCloseDialog = () => {
    if (!courseData.courseTitle) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else {
      storeData(0);
    }
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const [checkItisDraft, setCheckItisDraft] = useState(false);
  const storeData = (isDraft) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    // show loader
    setOpenBackdrop(true);
    axios
      .post(`${baseUrl}/orgPortal/addCourse`, courseData)
      .then((response) => showDataPostMessage(response, isDraft));
  };

  const showDataPostMessage = (response, isDraft) => {
    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error =
      typeof response.data.error == "undefined" ? true : response.data.error;

    if (error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error! can't save data, please try again.");
    } else {
      setOpenDialog(false);
      localStorage.removeItem("courseData");
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      const successMessage = isDraft
        ? "Course added as Draft! you will redirect to listing page."
        : "Course added successfully! you will redirect to listing page.";
      setSnackbarMessage(successMessage);
      setTimeout(() => {
        // Redirects to course listing page
        navigate("/courses-listing", { replace: true });
      }, 2000);
    }
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  // console.log(courseRelationalData.courseLanguages);

  /**
   * Data validation function
   * @param {*} activeStep
   */

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const courseErrorsInitialObject = {
    courseTitle: "",
    leadInstructor: "",
    courseImage: "",
    promotionalVideo: "",
    courseRefId: "",
    courseRefIdError: "",
    timezone: "",
    enrollmentStartDate: "",
    enrollmentEndDate: "",
    sessionStartDate: "",
    sessionEndDate: "",
    totalSessions: "",
    totalHours: "",
    totalLiveSessions: "",
    courseLearningObjs: "",
    seats: "",
    ageGroups: "",
    gradeLevels: "",
    zoomLink: "",
    preCourses: "",
    price: "",
  };

  const [courseErrors, setCourseErrors] = useState(courseErrorsInitialObject);
  const [errorStatus, setrrorStatus] = useState(false);

  const dataValidation = (activeStep, batchOperation = false) => {
    const activeStepLabel = steps[activeStep];
    let validationError = false;
    let validationErrorMessage = "No error found";
    let today = new Date();
    let errorObject = { ...courseErrors };

    const enrollmentStartDate = new Date(courseData.enrollmentStartDate);
    const enrollmentEndDate = new Date(courseData.enrollmentEndDate);
    const sessionStartDate = new Date(courseData.sessionStartDate);
    const sessionEndDate = new Date(courseData.sessionEndDate);

    const isSessionStartBeforeEnrollmentStart =
      sessionStartDate < enrollmentStartDate;
    const isSessionEndBeforeEnrollmentStart =
      sessionEndDate < enrollmentStartDate;

    if (activeStepLabel == "Course Overview") {
      let errors = 0;
      if (Object.keys(courseData).length == 0) {
        // validationError = true;
        errors++;
        // validationErrorMessage = "No course data set yet";
        errorObject.courseTitle = "No course data set yet";
      } else {
        validationErrorMessage = "";
        errorObject.courseTitle = "";
      }
      if (!courseData.courseTitle) {
        // validationError = true;
        errors++;
        // validationErrorMessage = "Course title field is required";
        errorObject.courseTitle = "Required";
      } else {
        validationErrorMessage = "";
        errorObject.courseTitle = "";
      }
      if (!courseData.leadInstructor) {
        // validationError = true;
        errors++;
        // validationErrorMessage = "Lead Instructor field is required";
        errorObject.leadInstructor = "Select instructor";
      } else {
        validationErrorMessage = "";
        errorObject.leadInstructor = "";
      }
      if (!courseData.courseImage) {
        errors++;
        // validationError = true;
        // validationErrorMessage = "Course image field is required";
        errorObject.courseImage = "Required";
      } else {
        validationErrorMessage = "";
        errorObject.courseImage = "";
      }
      if (
        !validateYouTubeUrl(courseData.promotionalVideo) &&
        courseData.promotionalVideo
      ) {
        // validationError = true;
        errors++;
        // validationErrorMessage = "Enter a valid YouTube URL";
        errorObject.promotionalVideo = "Enter a valid YouTube URL";
      } else {
        validationErrorMessage = "";
        errorObject.promotionalVideo = "";
      }
      if (errors > 0) {
        validationErrorMessage = "Required felids are left";
        validationError = true;
      } else {
        validationError = false;
      }
    } else if (activeStepLabel == "Settings") {
      let errorStepSettings = 0;
      if (!courseData.courseRefId) {
        errorStepSettings++;
        // validationErrorMessage = "Course Id field is required";
        errorObject.courseRefId = "Course Id field is required";
      } else {
        validationErrorMessage = "";
        errorObject.courseRefId = "";
      }

      if (courseData.courseRefIdError) {
        errorStepSettings++;
        // validationErrorMessage = "Course ID already exists";
        errorObject.courseRefIdError = "Course ID already exists";
      } else {
        validationErrorMessage = "";
        errorObject.courseRefIdError = "";
      }

      if (!courseData.timezone) {
        errorStepSettings++;
        // validationErrorMessage = "Timezone field is required";
        errorObject.timezone = "Timezone field is required";
      } else {
        validationErrorMessage = "";
        errorObject.timezone = "";
      }

      if (!courseData.enrollmentStartDate) {
        errorStepSettings++;
        // validationErrorMessage = "Enrollment start date field is required";
        errorObject.enrollmentStartDate =
          "Enrollment start date field is required";
      } else if (
        today > enrollmentStartDate &&
        !isSameDate(today, enrollmentStartDate) &&
        courseId === "0"
      ) {
        validationError = true;
        // validationErrorMessage = "Enrollment start date cannot be in the past";
        errorObject.enrollmentStartDate =
          "Enrollment start date cannot be in the past";
      } else {
        validationErrorMessage = "";
        errorObject.enrollmentStartDate = "";
      }

      if (!courseData.enrollmentEndDate) {
        errorStepSettings++;
        // validationErrorMessage = "Enrollment end date field is required";
        errorObject.enrollmentEndDate = "Enrollment end date field is required";
      } else if (
        courseData.enrollmentStartDate > courseData.enrollmentEndDate
      ) {
        errorStepSettings++;
        // validationErrorMessage =
        //   "The enrollment start date should be lower than the enrollment end date";
        errorObject.enrollmentEndDate =
          "The enrollment start date should be lower than the enrollment end date";
      } else {
        validationErrorMessage = "";
        errorObject.enrollmentEndDate = "";
      }

      if (!courseData.sessionStartDate) {
        errorStepSettings++;
        // validationErrorMessage = "Session start date field is required";
        errorObject.sessionStartDate = "Session start date field is required";
      } else if (
        today > sessionStartDate &&
        !isSameDate(today, sessionStartDate) &&
        courseId === "0"
      ) {
        errorStepSettings++;
        // validationErrorMessage = "Session start date cannot be in the past";
        errorObject.sessionStartDate =
          "Session start date cannot be in the past";
      } else if (
        courseData.sessionStartDate > courseData.sessionEndDate &&
        courseData.sessionEndDate
      ) {
        errorStepSettings++;
        // validationErrorMessage =
        //   "The session start date should be lower than the session end date";
        errorObject.sessionEndDate =
          "The session start date should be lower than the session end date";
      } else if (isSessionStartBeforeEnrollmentStart) {
        errorStepSettings++;
        // validationErrorMessage = "Backdate can't be accepted";
        errorObject.sessionStartDate = "Backdate can't be accepted";
      } else {
        validationErrorMessage = "";
        errorObject.sessionStartDate = "";
      }

      if (isSessionEndBeforeEnrollmentStart) {
        errorStepSettings++;
        // validationErrorMessage = "Backdate can't be accepted";
        errorObject.sessionEndDate = "Backdate can't be accepted";
      } else {
        validationErrorMessage = "";
        errorObject.sessionEndDate = "";
      }

      if (parseInt(courseData?.totalSessions) < 0) {
        errorStepSettings++;
        // validationErrorMessage = "Total Sessions can't be negative";
        errorObject.totalSessions = "Total Sessions can't be negative";
      } else {
        validationErrorMessage = "";
        errorObject.totalSessions = "";
      }

      if (parseInt(courseData?.totalHours) < 0) {
        errorStepSettings++;
        // validationErrorMessage = "Total Hours can't be negative";
        errorObject.totalHours = "Total Hours can't be negative";
      } else {
        validationErrorMessage = "";
        errorObject.totalHours = "";
      }

      if (parseInt(courseData?.totalLiveSessions) < 0) {
        errorStepSettings++;
        // validationErrorMessage = "Total Live Sessions can't be negative";
        errorObject.totalLiveSessions = "Total Live Sessions can't be negative";
      } else {
        validationErrorMessage = "";
        errorObject.totalLiveSessions = "";
      }
      if (errorStepSettings > 0) {
        validationErrorMessage = "Required felids are left";
        validationError = true;
      } else {
        validationError = false;
      }
    } else if (activeStepLabel == "Learners") {
      if (courseData.courseLearningObjs.length < 2) {
        validationError = true;
        // validationErrorMessage =
        //   "At least 2 entries are required at learning objective";
        errorObject.courseLearningObjs =
          "At least 2 entries are required at learning objective";
      }
      if (courseData.seatsStatus == true && !courseData.seats) {
        validationError = true;
        // validationErrorMessage = "Required felids are left";
        errorObject.seats = "Required";
      }
      if (courseData.ageGroup == true && courseData.ageGroups.length == 0) {
        validationError = true;
        // validationErrorMessage = "Required felids are left";
        errorObject.ageGroups = "Required";
      }
      if (courseData.gradeLevel == true && courseData.gradeLevels.length == 0) {
        validationError = true;
        // validationErrorMessage = "Required felids are left";
        errorObject.gradeLevels = "Required";
      }
      if (validationError && courseData.courseLearningObjs.length < 2) {
        validationErrorMessage =
          "At least 2 entries are required at learning objective";
      } else if (validationError) {
        validationErrorMessage = "Required felids are left";
      } else {
        validationErrorMessage = "";
      }
    } else if (activeStepLabel == "Curriculum") {
      if (courseData.sessionLinkSetting == true && !courseData.zoomLink) {
        validationError = true;
        validationErrorMessage = "Required felids are left";
        errorObject.zoomLink = "Zoom link is required";
      } else if (
        courseData.sessionLinkSetting == true &&
        !isValidURL(courseData.zoomLink)
      ) {
        validationError = true;
        validationErrorMessage = "Required felids are left";
        errorObject.zoomLink = "Enter a valid zoom link";
      } else {
        validationError = false;
        validationErrorMessage = "";
        errorObject.zoomLink = "";
      }
    } else if (activeStepLabel == "Prerequisite") {
      if (
        courseData.prerequisite == true &&
        courseData.preCourses.length == 0
      ) {
        validationError = true;
        validationErrorMessage = "Select at least one prerequisite course";
        errorObject.preCourses = "Required";
      }
    } else if (activeStepLabel == "Price") {
      if (courseData.priceCat == "Paid" && !courseData.price) {
        validationError = true;
        validationErrorMessage = "Price field is required";
        errorObject.price = "Required";
      }
    }

    setCourseErrors({ ...courseErrors, ...errorObject });

    if (validationError) {
      setStepErrors((stepErrors) => ({
        ...stepErrors,
        [activeStepLabel]: true,
      }));
    } else {
      setStepErrors((stepErrors) => ({
        ...stepErrors,
        [activeStepLabel]: false,
      }));

      if (
        activeStepLabel == "Publish" &&
        batchOperation == false &&
        !dateError
      ) {
        // it's final step to store data
        storeData(0);
      }
    }

    return { error: validationError, message: validationErrorMessage };
  };

  useEffect(() => {
    if (errorStatus) {
      dataValidation(activeStep);
    }
  }, [errorStatus, courseData]);

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        return (
          <CourseOverview
            courseRelationalData={courseRelationalData}
            courseData={courseData}
            setCourseData={setCourseData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 1:
        return (
          <Description
            courseData={courseData}
            setCourseData={setCourseData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 2:
        return (
          <Settings
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 3:
        return (
          <Learners
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            showSnackbarMessage={showSnackbarMessage}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 4:
        return (
          <Curriculum
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            showSnackbarMessage={showSnackbarMessage}
            setShowSnackbar={setShowSnackbar}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 5:
        return (
          <Prerequisite
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      // case 5:
      //   return <Track courseData={courseData} setCourseData={setCourseData} courseRelationalData={courseRelationalData} />;
      case 6:
        return (
          <Price
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      case 7:
        return (
          <Publish
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            courseErrors={courseErrors}
            setCourseErrors={setCourseErrors}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setrrorStatus(true);
    let formValidation = {};

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    formValidation = dataValidation(activeStep);

    if (formValidation.error == false) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setrrorStatus(false);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(formValidation.message);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // useEffect(() => {

  //   console.log(courseData.enrollmentStartDate, 345, today);
  // }, [courseData]);
  const handleReset = () => {
    setActiveStep(0);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const breadcrumbsData = [
    {
      label: "Back",
      link: "/courses-listing",
      icon: <ArrowBackIosNewRoundedIcon fontSize="small" />,
    },
    {
      label: "Courses",
      link: "/courses-listing",
      icon: <MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: (
        <>
          {courseId === "0" ? "Add New Course" : "Edit course"}
          {/* {courseId === "0"
            ? `${
                courseData?.status === "draft"
                  ? "Save as Draft"
                  : "Add New Course"
              }`
            : "Edit course"} */}
        </>
      ),
      link: "",
    },
  ];

  //========== Stepper Click Able ==========//

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    if (!courseData.courseTitle) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  //========== Stepper Click Able ==========//

  // const ref = useRef(null);

  // useEffect(() => {
  //   centerCompletedStep();
  //   // console.log(ref, 2001);
  //   // ref.current?.scrollIntoView();
  // }, [activeStep]);

  // function centerCompletedStep() {
  //   const stepperContainer = document.querySelector(
  //     ".FormStepper .StapperContaier .StapperBody .MuiStepper-root"
  //   );
  //   const targetDiv = stepperContainer.querySelector(
  //     ".MuiStepConnector-root.Mui-active"
  //   );
  //   // targetDiv.scrollIntoView({
  //   //   behavior: "smooth",
  //   // });
  //   targetDiv.scrollTo({
  //     behavior: "smooth",
  //   });
  // }

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="ButtonSaveCont">
        <div className="ButtonSave">
          <Buttons
            label="Exit"
            buttonColor="#fff"
            border="#ccc 1px solid"
            color="#004FE0"
            width=""
            height="48px"
            fontSize="14px"
            // link="/courses-listing"
            onClick={() => handleExit()}
          />
          <Buttons
            label="Save As Draft"
            buttonColor="#004FE0"
            border="#ccc 1px solid"
            color="#fff"
            width=""
            height="48px"
            fontSize="14px"
            link=""
            onClick={() => saveDraft()}
          />
        </div>
      </div>

      <Breadcrumbs breadcrumbsData={breadcrumbsData} />

      <div className="FormStepper">
        <div className="StapperContaier">
          <div className="StapperBody Section stepper-container ">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    {/* <Tooltip
                      TooltipText="Coming Soon"
                      Tooltip={true}
                    /> */}
                    {/* {stepErrors[label]? "Error" : ""} */}
                    <StepLabel
                      {...labelProps}
                      onClick={handleStep(index)}
                      completed={completed[index]}
                    >
                      <div className="emptyIcon">
                        {label}
                        {"\u00A0"}
                        {stepErrors[label] && <ErrorRoundedIcon />}
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          <div className="StapperContent">
            {activeStep === steps.length ? (
              <div>
                {/* <Typography >
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} >
                Reset
              </Button> */}
              </div>
            ) : (
              <div>
                <Typography>
                  {getStepContent(activeStep, courseRelationalData)}
                </Typography>
                <div className="SpperButtonContainer">
                  <div className="StepperButton">
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="StepperBackButton"
                      sx={{
                        display: `${activeStep === 0 ? "none" : "inline-flex"}`,
                        alignItems: "center",
                      }}
                    >
                      <ArrowBackRoundedIcon /> Back
                    </Button>
                    <span
                      style={{
                        display: `${activeStep !== 0 ? "none" : "block"}`,
                      }}
                    ></span>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className="ButtonContinue"
                    >
                      {activeStep === steps.length - 1
                        ? courseData.status == "draft" ||
                          courseData.status == "active_for_track"
                          ? "Save"
                          : courseData.status == "public"
                          ? "Publish"
                          : "Private"
                        : "Continue"}{" "}
                      <ArrowForwardRoundedIcon />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <CustomizedSnackbar
          open={showSnackbar}
          severity={snackbarSeverity}
          message={snackbarMessage}
          handleClose={handleSnackbarClose}
        />
        <DraggableDialog
          openDialog={openDialog}
          title={dialogTitle}
          body={dialogBody}
          actionButton="Save And Exit"
          cancelButton="Exit Without Saving"
          handleCloseDialog={handleCloseDialog}
          handleConfirmCloseDialog={handleConfirmCloseDialog}
          ModalFooter={true}
        />
      </div>
    </>
  );
}

export default AddCourse;
