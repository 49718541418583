import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import { getUserData } from "../../hooks/HelperFunctions";

import { deepPurple } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import Badge from "@mui/material/Badge";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import "./PortalSwitcher.css";

function PortalSwitcher(props) {
  const [activePortal, setActivePortal] = useState(() => {
    const activePortal = localStorage.getItem("activePortal");
    if (activePortal) {
      return activePortal;
    } else {
      return 0;
    }
  });

  const [teachingAccounts, setTeachingAccounts] = useState(null);

  let userData = getUserData();
  const userId = userData.id;

  let projectSecondaryEnv = process.env.REACT_APP_TAKBIR_SECONDARY_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;

  const userToken = localStorage.getItem("userToken");
  var signinToken = CryptoJS.AES.encrypt(
    JSON.stringify({
      email: userData.email,
      phone_number: userData.phone_number,
      userToken: userToken,
    }),
    "token-based-signin-aglbnd-854256-secret-key"
  ).toString();
  console.log(signinToken);

  //====================create a link to targetPage become-a-teacher =======================//
  if (projectSecondaryEnv == "prod") {
    mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain.", "");
  } else {
    const hasLocalhost = mainWebsiteUrl.includes("localhost");

    if (!hasLocalhost) {
      if (projectSecondaryEnv == "dev") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-dev");
      } else if (projectSecondaryEnv == "stage") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-stage");
      }
    }
  }
  mainWebsiteUrl += `?signinToken=${signinToken}&targetPage=become-a-teacher`;
  //====================create a link to targetPage become-a-teacher =======================//

  const navigate = useNavigate();

  useEffect(() => {
    getTeachingAccountsList();
    // setActivePortal(localStorage.getItem('activePortal'));
  }, []);

  useEffect(() => {
    if (teachingAccounts && teachingAccounts.length > 0) {
      localStorage.setItem("studentBecomeTeacherLink", "");
    } else {
      localStorage.setItem("studentBecomeTeacherLink", mainWebsiteUrl);
    }
  }, [teachingAccounts]);

  // useEffect(() => {

  // }, [activePortal]);

  // fetching teaching accounts data
  const getTeachingAccountsList = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(`${baseUrl}/orgPortal/getUserOrgsData?userId=${userId}`)
      .then((result) => {
        console.log(result.data.body);
        setTeachingAccounts(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    const activePortalId = event.target.value;
    console.log(activePortalId);
    setActivePortal(activePortalId);
    const activePortal = activePortalId;
    localStorage.setItem("activePortal", activePortal);

    if (activePortal == 0) {
      localStorage.removeItem("orgId");
      localStorage.removeItem("subDomain");
      userData.userRole = "student";
      localStorage.setItem("userData", JSON.stringify(userData));
      navigate("/my-courses", { replace: true });
    } else if (activePortal > 0) {
      localStorage.setItem("orgId", activePortal);
      let orgInfoObject = {};
      if (teachingAccounts) {
        orgInfoObject = teachingAccounts.find(
          (item) => item.orgId === activePortal
        );
      }
      console.log(orgInfoObject);
      if (orgInfoObject?.subDomain) {
        localStorage.setItem("subDomain", orgInfoObject.subDomain);
      }
      userData.userRole = "org";
      localStorage.setItem("userData", JSON.stringify(userData));
      // navigate('/courses-listing', {replace: true});
      window.location.href = "/courses-listing";
    } else {
      // do nothing
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };

  return (
    <>
      {teachingAccounts && teachingAccounts.length > 0 ? (
        <div className="MenueDropdownWithImgCont">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={activePortal}
              label="Active Portal"
              onChange={handleChange}
              className="MenueDropdownWithImg"
            >
              <div className="SlImLstingTitle">LEARNER ACCOUNT</div>

              <MenuItem value={0} className="SlImLsting">
                <div className="SlImLst">
                  <RadioButtonUncheckedRoundedIcon className="listUnCheck" />
                  <RadioButtonCheckedRoundedIcon className="listCheck" />
                  <Avatar
                    alt={userData.firstName}
                    src={userData.image}
                    sx={{ bgcolor: deepPurple[300] }}
                  >
                    {userData.firstName
                      ? Array.from(userData.firstName)[0]
                      : ""}
                  </Avatar>
                  <div className="SlImLstlabel">
                    {truncateText(
                      userData.firstName + " " + userData.lastName,
                      30
                    )}{" "}
                  </div>
                </div>
              </MenuItem>

              <div className="SlImLstingTitle">TEACHING ACCOUNTS</div>
              {teachingAccounts.map((item) => (
                <MenuItem value={item.orgId} className="SlImLsting">
                  <div className="SlImLst">
                    <RadioButtonUncheckedRoundedIcon className="listUnCheck" />
                    <RadioButtonCheckedRoundedIcon className="listCheck" />
                    <Avatar
                      alt={item.accountName}
                      src={item.officialIcon}
                      sx={{ bgcolor: deepPurple[300] }}
                    >
                      {item.accountName ? Array.from(item.accountName)[0] : ""}
                    </Avatar>
                    <div className="SlImLstlabel">
                      {truncateText(item.accountName, 30)}{" "}
                      <label>{item.role}</label>
                    </div>
                  </div>
                </MenuItem>
              ))}

              <a
                className="CreateNewButtonContainer"
                href={mainWebsiteUrl}
                target="_blank"
              >
                <div className="CreateNewButton">
                  <div className="CreateNewIcon">
                    <AddRoundedIcon />
                  </div>
                  <div className="CreateNewLabel">New teaching account</div>
                </div>
              </a>
            </Select>
          </FormControl>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default PortalSwitcher;
