import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Table from "../../../../../components/Table/Table";
import tableImg from "../../../../../assets/imgs/tableImg.png";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import DraggableDialog from "../../../../../hooks/Dialog";
import axios from "axios";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import { TbFileDownload } from "react-icons/tb";
import MediaLibrayForCourseCertificate from "../../../../../components/MediaLibrary/MediaLibrayForCourseCertificate";
import moment from "moment";

const Certificates = () => {
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [activityDrawer, setActivityDrawer] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [assignDialog, setAssignDialog] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  // ? ==== Character limit ==== * //
  const truncateText = (text, limit) => {
    return text.length > 20 ? text.substring(0, 20) + "..." : text;
  };

  const headCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "30%",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
      width: "15%",
    },
    {
      id: "certificate",
      numeric: false,
      disablePadding: true,
      label: "Certificate",
      width: "30%",
    },
    {
      id: "Last Activity",
      numeric: false,
      disablePadding: true,
      label: "Last Activity",
      width: "15%",
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];

  const rowss = [
    {
      cell: [
        {
          textBold:
            "The Language Essentials The Language Essentials The Language Essentials",
        },
        { textBold: "Certificate track" },
        {
          text: (
            <Button
              variant="text"
              sx={{ textTransform: "initial", color: "#004FE0" }}
              startIcon={<TbFileDownload />}
            >
              {truncateText("CC-shayanmahmuds hayanm ahmudshay anmahmud.pdf")}
            </Button>
          ),
        },
        {
          text: "10-Apr-2023",
        },
        {
          ThreeDotButton: true,
          buttonLabel: <MoreVertIcon />,
          buttonTextcolor: "#004FE0",
          // buttonLink: "#",
          // buttonTarget: "_blank",
        },
      ],

      //======= Action Date Start===== //
      action: [
        { label: "Send Email", onClick: () => setEmailDialog(true) },
        { label: "View Activities", onClick: () => setActivityDrawer(true) },
        { label: "Delete", onClick: () => setDeleteDialog(true) },
      ],
      //======= Action Date End===== //
    },
    {
      cell: [
        { textBold: "Duty of parents In Islam about their children" },
        { textBold: "Diplomas" },
        {
          Button: true,
          ButtonContainerStyles: { justifyContent: "left" },
          justifyContent: "left",
          buttonLabel: "Upload",
          buttonColor: "#FFFFFF",
          padding: "0px",
          // buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonIcon: "PlusIcon",
          onClick: (e) => setOpenSelectMedia(true),
        },
        {},
        {},
      ],

      //======= Action Date Start===== //
      action: [
        { label: "Send Email", onClick: () => alert("hello") },
        { label: "View Activities", onClick: () => alert("view activity") },
        { label: "Delete", onClick: () => alert("hello") },
      ],
      //======= Action Date End===== //
    },
  ];

  return (
    <div>
      <div className="TableSection CertificatesTable">
        <Table
          AddButtonInner={false}
          innerTitle=""
          addLabelInner="+ Announcement"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          //   onClickInnerButton={() => handleOpenAddAnnoucement(true)}
          innerHeader={true}
          headCells={headCells}
          rows={rowss}
          perPageItems={10}
          Checkbox={false}
          footer={false}
          showSearchBox={true}
          // handleSearchText={handleSearchText}
          // handleSearch={handleSearch}
          //   iconButtonHandler={iconButtonHandler}
        />
      </div>

      {/* ========== Media Lib =========== */}
      <div>
        <SideDrawer
          title="Media Library"
          ButtonLabel="Assign"
          arrowIcon={true}
          //clickSaveButton={clickSaveButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openSelectMedia}
          handleDrawerClose={() => setOpenSelectMedia(false)}
          FooterWithoutTab={true}
          LeftButton={true}
          ButtonLeft="Upload New"
          ButtonLeftIcon={<FileUploadOutlinedIcon />}
          clickButtonLeft={() => setOpenNewMedia(true)}
          clickSaveButton={() => setAssignDialog(true)}
          body={<MediaLibrayForCourseCertificate />}
        />

        <Externaluse
          open={openNewMedia}
          handleCloseButton={() => setOpenNewMedia(false)}
          // handleOpenNewMedia={handleOpenNewMedia}
        />
      </div>
      {/* ======== Assign Modal ============= */}
      <DraggableDialog
        openDialog={assignDialog}
        title={"Assign certificate to the learner?"}
        body={
          <div>
            The certificate will be available for the learner to view and
            download from their portal.
            <br />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckBox} />}
              label="Send email to the learner"
              labelPlacement="end"
            />
          </div>
        }
        actionButton={checked ? "Assign & Send Email" : "Assign"}
        handleCloseDialog={() => {
          setChecked(false);
          setAssignDialog(false);
        }}
        // handleConfirmCloseDialog={
        //   !checked ? clickSelectMediaItem : assignAndSendEmail
        // }
        ModalFooter={true}
      />
      {/* ======== Activity Drawer ============= */}
      <SideDrawer
        title="Activities"
        buttonLabel=""
        // buttonLink={}
        sideOpen="right"
        open={activityDrawer}
        handleDrawerClose={() => setActivityDrawer(false)}
        FooterWithoutTab={true}
        ButtonLabel=""
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel="Add another after saving"
        // clickSaveButton={(e) => inviteNewUser()}
        body={
          <>
            <div className="certificateActivityList">
              <ul>
                <li>Email sent by [Logged in user full name]</li>
                <li>New certificate assigned by [Logged in user full name]</li>
                <li>Certificate deleted by [Logged in user full name]</li>
                <li>Email sent by [Logged in user full name]</li>
                <li>New certificate assigned by [Logged in user full name]</li>
              </ul>
            </div>
          </>
        }
      />
      {/* ======== Email Modal ============= */}
      <DraggableDialog
        openDialog={emailDialog}
        title={"Send email to the learner?"}
        body={
          "Email the learner to inform them that their certificate is now available for download."
        }
        actionButton="Send Email"
        handleCloseDialog={() => setEmailDialog(false)}
        // handleConfirmCloseDialog={handleSendEmail}
        ModalFooter={true}
      />

      {/* ======== Delete Modal ============= */}
      <DraggableDialog
        openDialog={deleteDialog}
        title={"Delete certificate"}
        body={"Are you sure, you want to delete this student certificate?"}
        actionButton="Delete"
        handleCloseDialog={() => setDeleteDialog(false)}
        // handleConfirmCloseDialog={handleRemoveCertiFicate}
        ModalFooter={true}
      />
      {/* <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      /> */}
    </div>
  );
};

export default Certificates;
